<template>
  <div>
    <ml-container>
      <ml-grid>
        <div class="tw-mt-12 tw-col-start-2 tw-col-span-6">
          <page-title class="tw-text-black tw-text-center">Tutorials</page-title>
          <dl class="tw-mt-6 tw-space-y-5">
            <div v-for="(faq, index) in faqs" v-bind:key="index"
              class="tw-border tw-py-4 tw-px-6 tw-border-gray-n1 tw-rounded-lg">
              <dt class="tw-text-sm">
                <!-- Expand/collapse question button -->
                <button @click="faq.show = !faq.show" type="button"
                  class="tw-text-left tw-w-full tw-flex tw-justify-between tw-items-start" aria-controls="faq-0"
                  aria-expanded="false">
                  <span class="tw-font-medium tw-text-gray-900 tw-text-black tw-flex tw-items-center tw-font-semibold">
                    <span class="tw-font-serif tw-text-2xl tw-mr-6 tw-text-gray-n2 tw-font-normal">{{ String(index +
                        1).padStart(2, '0')
                    }}</span>
                    {{ faq.question }}
                  </span>
                  <span class="tw-ml-6 tw-h-7 tw-flex tw-items-center">
                    <!--
                      Expand/collapse icon, toggle classes based on question open state.

                      Heroicon name: outline/chevron-down

                      Open: "-rotate-180", Closed: "rotate-0"
                    -->
                    <svg :class="[faq.show ? 'tw--rotate-180' : 'tw-rotate-0', 'tw-h-3 tw-w-3 tw-transform']"
                      xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 9" stroke="currentColor"
                      aria-hidden="true">
                      <path d="M1 1.5L7 7.5L13 1.5" stroke="#BDBDBD" stroke-width="2" stroke-linecap="round"
                        stroke-linejoin="round" />
                    </svg>
                  </span>
                </button>
              </dt>
              <dd class="tw-mt-3 tw-pr-12" id="faq-0" v-show="faq.show">
                <div v-for="(item, index) in faq.answers" v-bind:key="index">
                  <p class="tw-text-sm tw-leading-relaxed tw-mb-0">
                    <span v-html="item.line" />
                  </p>
                </div>
                <div class="tw-mt-3">
                  <video v-if="faq.video" controls @playing="play" @pause="pause(faq, $event)" @canplay="updatePaused"
                    :id="index">
                    <source :src="faq.video" type="video/mp4">
                    Sorry, your browser doesn't support embedded videos.
                  </video>
                  <a v-if="faq.url" :href="faq.url" target="_blank">{{ faq.question }}</a>
                </div>
              </dd>
            </div>
          </dl>
        </div>
      </ml-grid>
    </ml-container>
  </div>
</template>

<script>
import i18n from '@/i18n'
import MlContainer from "@/components/layout/MlContainer";
import PageTitle from "@/components/layout/titles/PageTitle";
import MlGrid from "@/components/layout/MlGrid";

export default {
  components: {
    MlGrid,
    PageTitle,
    MlContainer,
  },
  layout: 'basic',
  metaInfo() {
    return {
      title: i18n.t('vendorsFaq'),
      meta: [
        {
          name: 'description',
          content: i18n.t('description.vendors/faq/videos'),
        },
      ]
    }
  },
  data() {
    return {
      playing: false,
      videoElements: [],
      faqs: [
        {
          question: 'Quick Start Guide for Vendors',
          answers: [
            {
              line: ''
            },
          ],
          show: false,
          video: null,
          url: "https://spaces.mangolola.com/faq/vendor/M&L%20Vendor%20Quick%20Start%20Guide.pdf"
        },
        {
          question: 'How To Set Up Your Mango & Lola Account in Under 10 Minutes!',
          answers: [
            {
              line: ''
            },
          ],
          show: false,
          url: null,
          video: "https://spaces.mangolola.com/video/vendor/faq/How%20To%20Setup%20Your%20Account%20On%20M&L.mp4"
        },
        {
          question: 'How to Use Mango & Lola',
          answers: [
            {
              line: 'In your <strong>Dashboard</strong> there are only 6 sections that need to be filled out (~10 min to create an account)' +
                  '<br>' +
                  '<ul class="tw-list-disc">' +
                  '<li>' +
                  '<strong>Company Settings</strong>: Business name, address, phone, & social media links' +
                  '</li>' +
                  '<li>' +
                  '<strong>Showcase Work</strong>: Add your best photos & your business description. Couples want to hear what your business offers them and how you stand out. This is your place to shine!' +
                  '</li><li>' +
                  '<strong>Offers</strong>:' +
                  '<br>' +
                  'You can list multiple offers with prices (as many as you like) <br>OR<br> Simply add 1 offer with a starting price if you prefer to reveal less information upfront' +
                  '<br>' +
                  '*Remember: You need to publish at least 1 offer to show up in our filters.' +
                  '</li><li>' +
                  '<strong>Calendar</strong>: All Dates Are Set to Available. Mark the dates you are already booked.' +
                  '</li><li>' +
                  '<strong>Settings</strong>: Adjust your area of coverage + When you sign up you are set up as a vendor who offers “Booking Request”, meaning you pre-approve each booking by responding to a booking request within 48hrs (most vendors respond faster than that, but you have up to 48hrs to respond). If you want to accept “auto-booking”, then you can select it here.' +
                  '</li><li>' +
                  '<strong>Payments</strong>: We are a booking site, and payment are set up to go through Stripe, so you do need to set this up if you want to receive your payments' +
                  '</ul>'
            },
          ],
          show: false,
          url: null,
          video: null
        },
        {
          question: 'How to Respond to a Custom Quote Request',
          answers: [
            {
              line: 'STEPS between Couple & Vendor' +
                  '<ul class="tw-list-disc">' +
                  '<li>Couple sends Quote Request to vendor which turns a red button in their profile</li>' +
                  '<li>Vendor receives notification and email</li>' +
                  '<li>Vendor goes to Custom Quote section in Dashboard and creates a custom offer for Couple</li>' +
                  '<li>Couple receives notification and email</li>' +
                  '<li>Couple reviews offer and accepts or rejects Custom Quote</li>' +
                  '<li>If couple accepts Custom quote, Vendor needs to accept as well, and it turns into a Booking</li>' +
                  '</ul>'
            },
          ],
          show: false,
          url: null,
          video: null
        },
      ],
    }
  },

  methods: {
    updatePaused(event) {
      this.videoElements[event.target.id] = event.target.id;
      //console.log("updatePaused");
    },
    play(event) {
      //console.log("play",event.target.id);

      if (this.playing && (this.playing != event.target.id)) {
        //console.log("playing",this.playing);
        document.getElementById(this.playing).pause();
      }
      this.playing = event.target.id;
    },
    pause(faq, event) {
      //console.log("pause",event);
      //console.log("faq",faq);
      if (this.playing != event.target.id) faq.show = false;
      // this.playing[event.target.id] = false;
    },
  }
}
</script>
